$('.js-gallery-main-img').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

$('.js-gallery-main-img').slick({
  adaptiveHeight: false,
  arrows: false,
  asNavFor: '#gallery-thumbs',
  fade: true,
  lazyLoad: 'ondemand'
});
$('#gallery-thumbs').slick({
  arrows: false,
  asNavFor: '.js-gallery-main-img',
  focusOnSelect: true,
  infinite: false,
  slidesToShow: 5,
  responsive: [{
    breakpoint: 600,
    settings: {
      dots: true,
      slidesToScroll: 3,
      slidesToShow: 3
    }
  }]
});

$(function () {
  // Header Filters
  $('#gallery-make').change(function changeMake() {
    $('#gallery-model').val('');
    $('#gallery-brand').val('');
    $('#gallery-search form').submit();
  });

  $('#gallery-model').change(function changeModel() {
    $('#gallery-brand').val('');
    $('#gallery-search form').submit();
  });

  $('#gallery-brand').change(function changeBrand() {
    $('#gallery-search form').submit();
  });

})





// youtube gallery
function show_my_videos(data){
	html = ['<ul id="youtube-videos">'];
	$(data.feed.entry).each(function(entry){
		url = this.link[0].href;
		url_thumbnail = this.media$group.media$thumbnail[3].url;
		description = this.media$group.media$description.$t;
		html.push('<li><a href="'+url+'">');
		html.push('<img src="'+url_thumbnail+'" alt="'+description+'">');
		html.push('</a></li>');
	});
	html.push('</ul>');
	$("#videos").html(html.join(''));
}
$.ajax({
	type: "GET",
	url: "//gdata.youtube.com/feeds/users/I4t6j3DmaH_XoEQxoG-AzQ/uploads?alt=json-in-script&format=5",
	cache: false,
	dataType:'jsonp',
	success: function(data){
		show_my_videos(data);
                //If you want to see in console...
		 console.log(data);
                 console.log(data.feed.entry);
		 $(data.feed.entry).each(function(){
		 	console.log(this.link[0].href);
		 	console.log(this.media$group.media$thumbnail[3].url);
		 	console.log(this.media$group.media$description.$t);
		 });
  }
});